.search button {
  background-color: #fdbf00;
  border-color: #fdbf00;
}

.search button:hover {
  background-color: #fdbf00;
}

.form-row {
  align-items: baseline;
}

a.icon.icon-sm.rounded-circle.border {
  background-color: none;
  border: 1px solid #2d261a !important;
}

.widget-header:hover i {
  color: #fdbf00;
}

.widget-header a i {
  color: #fdbf00;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2d261a;
  border-color: #2d261a;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2d261a;
  border-color: #2d261a;
}

.btn-primary {
  color: #fff;
  background-color: #2d261a;
  border-color: #2d261a;
}

.bg {
  background-color: #2d261a;
}

.btn-outline-primary {
  color: #212529;
  background-color: #fdbf00;
  border-color: #fdbf00;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #fdbf00;
  border-color: #fdbf00;
}

a {
  color: #2d261a;
}

.navbar-main {
  padding-top: 0px;
}

nav.navbar-main a {
  color: #2d261a !important;
}

nav.navbar-main a:hover {
  color: #2d261a !important;
}

.contenido .section-intro > .container,
.contenido .section-content > .container {
  background: #fafafa;
}

/* nav.navbar-main::after {
        content: "";
        display: block;
        position: absolute;
        height: 20px;
        left: 0px;
        width: 100%;
        top: 40px;
        background-image: url('/resource/header-border-bottom-image.png');
        background-repeat: repeat-x;
    } */

footer {
  position: relative;
}

/* footer::before {
        content: "";
        display: block;
        position: absolute;
        height: 20px;
        left: 0px;
        width: 100%;
        top: -10px;
        background-image: url('/resource/footer-border-top-image.png');
        background-repeat: repeat-x;
    } */

.section-heading {
  padding-bottom: 20px;
  padding-top: 15px;
  margin-bottom: 0;
  margin-top: 0;
}

.padding-y-sm {
  padding-top: 20px;
  padding-bottom: 0px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #2d261a;
  border-color: #2d261a;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2d261a;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.header-main {
  padding-bottom: 0px;
}

.brand-wrap .logo,
.navbar-brand .logo {
  margin-right: 1px;
  max-height: inherit;
  position: absolute;
  display: inline-block;
  z-index: 2;
  margin-top: -16px;
}

@font-face {
  font-family: shelby;
  src: url(../fonts/shelby.otf);
}

h3 > span {
  font-family: shelby;
  color: white;
  line-height: 0px;
  font-size: 50px;
  display: inline-block;
}

.section-footer .title {
  color: #fdbf00;
}

.img-wrap img.w-80px {
  width: 80px !important;
}

.card-banner.primary {
  height: 350px;
  /* background-image: url("/resource/slide3.png"); */
}

.card-banner.secondary {
  background: #222;
  height: 170px;
  border-radius: 7px;
  margin-bottom: 10px;
  /* background-image: url("/resource/categoria-salados.jpg"); */
  background-size: cover;
  position: relative;
}

.category-card {
  height: 250px;
}

.categoria-header .section-heading {
  padding-bottom: 5px;
  padding-top: 23px;
  margin-bottom: 0;
  margin-top: 0;
}

.categoria-header .container {
  background-color: #2d261a !important;
  text-transform: lowercase;
}

.categoria-header img {
  max-height: 40px;
  letter-spacing: 1px;
  vertical-align: text-bottom;
  display: inline-block;
}

.producto-detalle .btn.btn-primary {
  border: #fdbf00;
  background-color: #fdbf00;
  color: #252525;
}

.producto-informacion-nutricional .header-info {
  text-align: center;
  padding-bottom: 10px;
}
